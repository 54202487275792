<template>
  <section class="pb-2">
    <b-card body-class="p-0">
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Il tuo carrello</span>
        </div>
      </template>
    </b-card>

    <div id="step-1" v-if="step == 1">
      <b-row>
        <b-col md="8">
          <b-card v-for="car in cartItems" :key="car.id_auto">

            <div class="d-flex align-items-center">

            <div class="mr-2">
                <b-img fluid src="https://via.placeholder.com/300x250"></b-img>
            </div>

            <div class="flex-grow-1">
              <p class="text-primary-light">
                <b>{{ car.marca }} - {{ car.modello }} - {{ car.versione }}</b>
              </p>

              <p class="text-primary-light">
                Targa: <span class="text-dark">{{ car.targa }}</span>
              </p>
              <p class="text-primary-light">
                Telaio: <span class="text-dark">{{ car.telaio }}</span>
              </p>
              <div class="d-flex">
                <b-img src="@/assets/icons/cambio.svg" height="32"></b-img>
                <span>{{car.cambio}}</span>
              </div>
              <div class="d-flex mt-1">
                <b-img class="mr-1" src="@/assets/icons/road.svg" height="20"></b-img>
                <span>{{car.chilometraggio}} km</span>
              </div>
              <p class="text-primary-light mt-1">
                
                Cavalli: <span class="text-dark">{{car.cavalli}}</span>
              </p>
              <p class="text-primary-light mt-1">
                <b-img class="mr-1" src="@/assets/icons/engine.svg" height="20"></b-img>
                <span class="text-dark">{{ car.potenza }} CV</span>
              </p>
            </div>

            <div class="text-center flex-grow-1">
                <h3 class="text-primary-light">€{{car.prezzo_rivenditore}}</h3>
                <b-button block class="my-2" @click="removeFromCart(car)">Rimuovi</b-button>
                <b-button block variant="primary">Aggiungi ai preferiti</b-button>
            </div>

            </div>
          </b-card>
        </b-col>

        <b-col md="4">
          <b-card>
            <p class="h5">Riepilogo spese</p>


            <div class="d-flex justify-content-between" v-for="car in cartItems" :key="car.id_auto">
                <p>
                    {{ car.marca }} {{ car.modello }} {{ car.versione }}
                </p>

                <p>
                    €{{ car.prezzo_rivenditore }}
                </p>
            </div>

            <hr />

            <div class="d-flex justify-content-between">
              <span class="h5">Totale</span>
              <span class="h5">€{{total_price}}</span>
            </div>

            <div class="text-center mt-2">
              <b-button variant="primary-light" @click="step++">
                Procedi
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div id="step-2" v-if="step == 2">
      <b-card body-class="p-0">
        <div class="p-2">
          <h3 class="text-primary-light">Riepilogo del tuo ordine</h3>

          <b-row class="mt-2">
            <b-col md="8">
              <div
                class="d-flex justify-content-between border-bottom mb-1"
                v-for="car in cartItems"
                :key="car.id_auto"
              >
                <p class="text-primary-light">
                  {{ car.marca }} {{ car.modello }} {{ car.versione }}
                </p>

                <p>€{{ car.prezzo_rivenditore }}</p>
              </div>
            </b-col>

            <b-col class="text-center" md="4">
              <b-img fluid src="@/assets/images/auto-outline.svg"></b-img>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="12">
              <b-form-checkbox
                >Accetto le condizioni di vendita</b-form-checkbox
              >
            </b-col>
          </b-row>
        </div>

        <hr />

        <div class="p-2">
          <h5 class="text-primary-light">Riepilogo <b>totale</b></h5>
          <div class="d-flex justify-content-between">
            <h4 class="text-primary-light">TOTALE CARRELLO:</h4>
            <h4 class="text-primary-light">€{{total_price}}</h4>
          </div>

          <div class="text-right">
            <b-button variant="primary" @click="orderCreate"
              >Concludi l'ordine</b-button
            >
          </div>
        </div>
      </b-card>
    </div>

    <div id="step-3" v-if="step == 3">
        <b-card>
            <h3 class="text-primary-light">Hai concluso il tuo ordine!</h3>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</br> tempor incididunt ut labore et dolore magna aliqua</p>

            <b-button variant="primary">Stampa contratto</b-button>
        </b-card>
    </div>
  </section>
</template>

<script>
import { BButton, BCol, BRow, BCard, BImg, BFormCheckbox } from "bootstrap-vue";
import {mapActions} from 'vuex';

import { Requests } from "@/api";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BImg,
    BFormCheckbox,
  },

  data: () => ({
    cartItems: [],

    //Helpers

    loading: false,
    step: 1,
  }),

  computed: {
    total_price() {
        let sum = 0
        this.cartItems.forEach(a => sum += parseInt(a.prezzo_rivenditore))
        return sum;
    }
  },

  created() {
    this.getCartItems();
  },

  methods: {
    ...mapActions({removeItem: 'removeItem'}),

    async getCartItems() {
      this.loading = true;

      try {
        const response = await Requests.getCartItems();
        this.cartItems = response.data;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async removeFromCart(auto) {
      this.loading = true;

      let request_data = new FormData();

      request_data.append('id_auto', auto.id_auto)

      try {
        await Requests.removeFromCart(request_data);
        const index = this.cartItems.indexOf(auto);
        this.cartItems.splice(index, 1);
        this.removeItem();
      } catch(err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async orderCreate() {
      this.loading = true;

      try {
        await Requests.orderCreate(); 
        this.step = 3;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    }
  },

};
</script>
